var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticStyle: {
      "background": "#141718",
      "overflow": "hidden"
    }
  }, [_vm.isAdminPanelRoute ? _c('v-app-bar', {
    attrs: {
      "app": "",
      "color": "black",
      "dark": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$store.commit('setDrawer', !_vm.$store.state.drawer);
      }
    }
  }), _vm.user && _vm.user.level > 0 ? _c('v-spacer') : _vm._e(), _vm.user && _vm.user.level > 0 ? _c('span', [_vm._v("Usuário: "), _c('strong', [_vm._v(_vm._s(_vm.user.name))])]) : _vm._e()], 1) : _vm._e(), _vm.isAdminPanelRoute ? _c('navigation-drawer') : _vm._e(), !_vm.isAdminPanelRoute ? _c('NewHeaderComponentVue', {
    on: {
      "update-menu-state": function ($event) {
        _vm.isMenuOpen = $event;
      }
    }
  }) : _vm._e(), !_vm.isAdminPanelRoute ? _c('v-main', {
    class: {
      'blur-background': _vm.isMenuOpen,
      'pt-0': true
    }
  }, [_c('v-overlay', {
    attrs: {
      "value": _vm.loadingRoute
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-2"
  }, [_vm._v("Carregando")])])], 1), _vm.loadingRoute ? _c('div', {
    staticStyle: {
      "min-height": "100vh"
    }
  }) : _c('router-view'), _c('notifications', {
    attrs: {
      "group": "app"
    }
  }), _c('NewFooterComponent')], 1) : _vm._e(), _vm.isAdminPanelRoute ? _c('v-main', {
    style: _vm.blurBackground ? 'filter: blur(5px)' : ''
  }, [_c('v-overlay', {
    attrs: {
      "value": _vm.loadingRoute
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('h5', {
    staticClass: "mb-0 mt-2"
  }, [_vm._v("Carregando")])])], 1), _vm.loadingRoute ? _c('div', {
    staticStyle: {
      "min-height": "100vh"
    }
  }) : _c('router-view'), _c('notifications', {
    attrs: {
      "group": "app"
    }
  }), !_vm.isAdminPanelRoute ? _c('NewFooterComponent') : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }