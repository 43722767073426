import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
//then you use Vuex
Vue.use(Vuex);

function routerPlugin(router) {
  return (store) => {
    router.afterEach((to, from) => {
      if (to.path === "/admin") {
        store.commit("setDrawer", true);
      } else {
        store.commit("setDrawer", false);
      }
    });
  };
}

export default new Vuex.Store({
  state: {
    user: null,
    drawer: false,
    loadingRoute: false,
    mobile: false,
    notification: false,
    sale: null,
    filesSelected: null,
    picturesListItems: [],
    eventDetails: null,
    currentTab: 0,
  },
  mutations: {
    setUser: (state, data) => {
      state.user = data;
    },
    setDrawer: (state, data) => {
      state.drawer = data;
    },
    setEventDetails(state, eventDetails) {
      state.eventDetails = eventDetails;
    },
    setLoading: (state, bool) => {
      state.loadingRoute = bool;
    },
    setMobile: (state, bool) => {
      state.mobile = bool;
    },
    setNotification: (state, data) => {
      state.notification = data;
    },
    setSale: (state, data) => {
      state.sale = data;
    },
    setFilesSelected: (state, data) => {
      /**
       * Quando adicionando nova galeria, ao enviar a(s) primeira(s) foto(s), é salva a galeria.
       * Ao fazer isto, é trocada a rota para a rota de edição de galeria, e com isso se perderia os arquivos selecionados
       * Estes são salvos aqui e recuperados logo em seguida
       */
      state.filesSelected = data;
    },
    setPicturesListItems: (state, data) => {
      /**
       * Ao acessar a visualização de galeria com itens customizados (PicturesListComponent.vue no mode 3), devemos salvar os itens a serem visualizados aqui.
       */
      state.picturesListItems = data;
    },
    setCurrentTab(state, tab) {
      state.currentTab = tab;
    },
  },
  plugins: [routerPlugin(router)],
});
