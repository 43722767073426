import "es6-promise/auto";
import App from "./App.vue";
import Vue from "vue";
import axios from "axios";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "font-awesome/css/font-awesome.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import pt from "vuetify/src/locale/pt.ts";
import store from "./store";
import router from "./router";
import VueRouter from "vue-router";
import Notifications from "vue-notification";
import VuetifyMoney from "vuetify-money";
import $ from "jquery";
import VueCardFormat from "vue-credit-card-validation";
import DatetimePicker from "vuetify-datetime-picker";
import * as debounce from "lodash.debounce";
import AOS from "aos";
import "/node_modules/aos/dist/aos.css";
import moment from "moment";

AOS.init();

Vue.prototype.$apiHost = (() => {
  let finalUrl = process.env.VUE_APP_API_HOST;
  if (finalUrl.startsWith("http") || finalUrl.startsWith("https")) {
    finalUrl = finalUrl.replace('http://', '').replace('https://', '')
  }
  if (process.env.VUE_APP_API_MODE == "production") {
    const currentHost = window.location.hostname.replaceAll(".", "-");
    finalUrl = `https://${currentHost}.${finalUrl}`;
  } else {
    finalUrl = `http://${finalUrl}`;
  }
  return finalUrl;
})();
Vue.prototype.$livenessHost = process.env.VUE_APP_LIVENESS_HOST;

axios.defaults.baseURL = `${Vue.prototype.$apiHost}/api`;
axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "application/json",
  Authorization: `Bearer ${localStorage.getItem("token_app")}`,
};
axios.defaults.withCredentials = true;
function hideScrollBar() {
  setTimeout(() => {
    $("html").addClass("overflow-y-hidden");
  }, 155);
}

function showScrollBar() {
  setTimeout(() => {
    $("html").removeClass("overflow-y-hidden");
  }, 155);
}

window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

Vue.prototype.$syncWithPath = function (watchableOrWatchableSet, customDelay) {
  const keys = [];
  if (Array.isArray(watchableOrWatchableSet)) {
    watchableOrWatchableSet.forEach((w) => {
      keys.push(w);
    });
  } else {
    keys.push(watchableOrWatchableSet);
  }

  const key = JSON.stringify(keys);

  const getNestedProperty = (obj, path) => {
    const keys = path.split(".");
    let currentObj = obj;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      // Se o objeto atual não tiver a chave, retorne undefined.
      if (!currentObj.hasOwnProperty(key)) return undefined;
      // Se for o último elemento da array 'keys', retorne o valor.
      if (i === keys.length - 1) return currentObj[key];
      // Caso contrário, acesse a próxima propriedade aninhada.
      currentObj = currentObj[key];
    }
  };

  this.$debounce(
    `$syncWithPath.for.${key}`,
    () => {
      console.log("running debounce for ", key);
      const currentQuery = this.$route?.query ?? {};
      Object.keys(currentQuery).forEach((currentQueryKey) => {
        currentQuery[currentQueryKey] = currentQuery[currentQueryKey]
          ? `${currentQuery[currentQueryKey]}`
          : null;
      });
      const newQuery = {
        ...currentQuery,
      };
      keys.forEach((k) => {
        const [prop, customPropKey] = k.split(":");
        const propKey = customPropKey ?? prop;
        const propValue = getNestedProperty(this, prop);
        newQuery[propKey] = propValue ? `${propValue}` : null;
        console.log("newQuery", propKey, propValue);
      });
      console.log({
        v1: JSON.stringify(currentQuery),
        v2: JSON.stringify(newQuery),
      });
      if (JSON.stringify(currentQuery) != JSON.stringify(newQuery)) {
        this.$router.replace({
          path: this.$route.path,
          query: newQuery,
        });
      }
    },
    customDelay ?? 0
  );
};

Vue.prototype.$moment = (v) => moment(v).locale('pt-br');

Vue.component("app-init", require("./App.vue").default);
Vue.prototype.$http = axios;
Vue.prototype.$hideScrollBar = hideScrollBar;
Vue.prototype.$showScrollBar = showScrollBar;
Vue.prototype.$resetAxiosConfig = function () {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token_app")}`,
  };
};
let _debounces = {};
Vue.prototype.$debounce = (k, f, d) => {
  if (_debounces[k]) _debounces[k]?.cancel();
  _debounces[k] = debounce(() => {
    f();
    delete _debounces[k];
  }, d ?? 650);
  _debounces[k]();
};

Vue.prototype.$userLevels = [
  {
    level: 0,
    name: "Cliente",
  },
  {
    level: 1,
    name: "Fotógrafo",
  },
  {
    level: 2,
    name: "Administrador Geral",
  },
  {
    level: 3,
    name: "Vendedor",
  },
  {
    level: 4,
    name: "Administrador Whitelabel",
  },
];
Vue.prototype.$formatCurrency = (v) =>
  Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(v);

Vue.prototype.$formatDate = (v, emptyString, full, options) =>
  v
    ? Intl.DateTimeFormat(
        "pt-BR",
        full
          ? options ?? {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }
          : {}
      ).format(new Date(v))
    : emptyString || null;

Vue.prototype.$tinyMceUploadHandler = async (
  blobInfo,
  success,
  failure,
  progress
) => {
  var formData = new FormData();
  formData.append("file", blobInfo.blob(), blobInfo.filename());

  await Vue.prototype.$http
    .post("upload", formData, {
      onUploadProgress: (progressEvent) => {
        progress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    })
    .then((r) => {
      success(r?.data?.path ?? failure("no path received"));
    })
    .catch((e) => {
      failure(e);
    });
};

Vue.prototype.$localFileOnServer = (url, defaultValue) =>
  url
    ? `${Vue.prototype.$apiHost}/${url.replace(/^\//, "")}`
    : defaultValue ?? null;

Vue.prototype.$gatewaysNames = {
  Iugu: "Iugu",
  Pagarme: "Pagar.me",
  Blacktag: "BlackTag",
};

Vue.prototype.$fileurl = function (v, isLocal) {
  if (!v) return null;
  if (!v.startsWith("/")) {
    v = `/${v}`;
  }
  if (isLocal) {
    return `${this.$apiHost}${v}`;
  }
  return `${process.env.VUE_APP_API_HOST}${v}`;
};

Vue.prototype.$sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

Vue.prototype.$encode = (v) => {
  v = btoa(v);
  v = v.replaceAll("=", "EQLSTSBL");
  v = v.replaceAll("/", "SLSHSBL");
  v = v.replaceAll("+", "PLSBL");
  return v;
};
Vue.prototype.$decode = (v) => {
  v = v.replaceAll("EQLSTSBL", "=");
  v = v.replaceAll("SLSHSBL", "/");
  v = v.replaceAll("PLSBL", "+");
  v = atob(v);
  return v;
};

Vue.use(Vuetify);
Vue.use(Notifications);
Vue.use(VueRouter);
Vue.use(VueCardFormat);
Vue.router = router;
Vue.use(VuetifyMoney);
Vue.use(DatetimePicker);

export default new Vuetify({
  icons: {
    iconfont: "fa4",
    iconfont: "md",
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});

new Vue({
  render: function (h) {
    return h(App);
  },
  store,
  router,
  vuetify: new Vuetify(),
}).$mount("#app");
