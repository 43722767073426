<template>
  <v-app style="background: #141718; overflow: hidden;">

    <!-- Header -->
    <v-app-bar v-if="isAdminPanelRoute" app color="black" dark>
      <v-app-bar-nav-icon @click.stop="$store.commit('setDrawer', !$store.state.drawer)"></v-app-bar-nav-icon>
      <v-spacer v-if="user && user.level > 0"></v-spacer>
      <span v-if="user && user.level > 0">Usuário: <strong>{{ user.name }}</strong></span>
    </v-app-bar>
    <navigation-drawer v-if="isAdminPanelRoute"></navigation-drawer>
    <NewHeaderComponentVue @update-menu-state="isMenuOpen = $event" v-if="!isAdminPanelRoute"></NewHeaderComponentVue>

    <!--Home sem login  -->
    <v-main v-if="!isAdminPanelRoute" :class="{ 'blur-background': isMenuOpen, 'pt-0': true }" >
      <v-overlay :value="loadingRoute">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-row class="ma-0" justify="center" align="center">
          <h5 class="mb-0 mt-2">Carregando</h5>
        </v-row>
      </v-overlay>
      <div v-if="loadingRoute" style="min-height: 100vh;"></div>
      <router-view v-else></router-view>
      <notifications group="app" />
      <NewFooterComponent></NewFooterComponent>
    </v-main>
    <!-- ---------- -->
    <v-main v-if="isAdminPanelRoute" :style="blurBackground ? 'filter: blur(5px)' : ''">
      <v-overlay :value="loadingRoute">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-row class="ma-0" justify="center" align="center">
          <h5 class="mb-0 mt-2">Carregando</h5>
        </v-row>
      </v-overlay>
      <div v-if="loadingRoute" style="min-height: 100vh;"></div>
      <router-view v-else></router-view>
      <notifications group="app" />

      <NewFooterComponent v-if="!isAdminPanelRoute"></NewFooterComponent>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    NavigationDrawer: () => import("@components/NavigationDrawer"),
    NewHeaderComponentVue: () => import("@components/NewHeaderComponent.vue"),
    HeaderComponent: () => import("@components/HeaderComponent.vue"),
    NewFooterComponent: () => import("@components/NewFooterComponent.vue"),
},
  data() {
    return {
      loading: false,
      blurBackground: false,
      isMenuOpen: false,
    };
  },
  // watch: {
  //   user(v) {
  //     if (window.location.origin === 'https://novo.uoon.com.br') {
 
  //       if (v && v.id && OneSignal) {
  //         OneSignal.setExternalUserId(`${v.id}`);
  //         OneSignal.showSlidedownPrompt();
  //       }
  //     }
  //   },
  // },
  computed: {
    route() {
      return this.$route?.path;
    },
    loadingRoute() {
      return this.$store?.state?.loadingRoute;
    },
    user() {
      return this.$store?.state?.user;
    },
    isAdminPanelRoute() {
      return (
        this.$route?.path.includes("/admin") ||
        this.$route?.path.includes("/photographer") ||
        this.$route?.path.includes("/seller")
      );
    },
    isHome() {
      return this.$route?.name == "Home";
    },
    isLoginRoute() {
      return this.$route?.path.includes("/login");
    },
  },
  created() {
    window.addEventListener("resize", this.checkScreenType);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenType);
  },
  mounted() {
    this.checkScreenType();
    let self = this;
    // window.OneSignal = window.OneSignal || [];
    // OneSignal.push(function () {
    //   OneSignal.init({
    //     appId: "53980cb8-1312-4740-b209-95add045a5ee",
    //   });
    //   OneSignal.on("notificationDisplay", function (e) {
    //     console.log("event onesignal", { e });
    //     self?.$store?.commit("setNotification", e);
    //     setTimeout(() => {
    //       self?.$store?.commit("setNotification", null);
    //     }, 5000);
    //   });
    // });
  },
  methods: {
    checkScreenType() {
      if (window.mobileAndTabletCheck()) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
    handleMenuToggle(isOpen) {
      this.blurBackground = isOpen; // Atualiza o estado do blur quando o menu é alternado
    },
  },
};
</script>

<style></style>