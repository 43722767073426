import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import axios from "axios";

const routes = [];
routes.push({
  path: "/",
  component: () => import("./pages/Home.vue"),
  name: 'HomePage',
  meta: {
    publicName: 'Página Inicial',
  }
});
routes.push({
  path: "/events-home/:categoryId?",
  component: () => import("./pages/Events.vue"),
  name: 'events.page',
  meta: {
    publicName: 'Projetos',
  }
});
routes.push({
  path: "/events-home/:id/pictures",
  component: () => import("./pages/EventImages.vue"),
  name: 'events.images.page',
  meta: {
    publicName: 'Imagens do Projeto',
  }
});
routes.push({
  path: "/as",
  component: () => import("./pages/BlankPage.vue"),
  name: "BlankPage",
});
routes.push({
  path: "/sobre-nos",
  component: () => import("./pages/AboutUsPage.vue"),
  name: "AboutUs",
});
routes.push({
  path: "/Como-funciona",
  component: () => import("./pages/TutorialPage.vue"),
  name: "Tutorial",
});
routes.push({
  path: "/events",
  component: () => import("./pages/User/Events/List.vue"),
  name: "events.index",
  meta: {
    publicName: "Projetos",
    levels: [0],
  }
});
routes.push({
  path: "/products/:eventId",
  component: () => import("./pages/User/Products/List.vue"),
  name: 'products.index',
  meta: {
    publicName: "Produtos do Projeto",
    levels: [0],
  }
});
routes.push({
  path: "/products/:eventId/service/:serviceId/pay/:retryOrderId?",
  component: () => import("./pages/User/Payment/Payment.vue"),
  name: 'products.payment',
  meta: {
    publicName: "Pagamento",
    levels: [0],
  }
});
routes.push({
  path: "/sessions/:eventId",
  component: () => import("./pages/Sessions.vue"),
  name: "sessions.index",
  meta: {
    publicName: "Eventos de Projeto",
    levels: [0],
  },
});
routes.push({
  path: "/sessions/:eventId/gallery/:sessionId?",
  component: () => import("./pages/User/Gallery/Gallery.vue"),
  name: "gallery.list",
  meta: {
    publicName: "Galeria",
    levels: [0],
  },
});
routes.push({
  path: "/sac",
  component: () => import("./pages/Sac.vue"),
  name: "SAC",
});
routes.push({
  path: "/contribuir-com-vaquinha/:hash",
  component: () => import("./pages/Passthehat.vue"),
  name: "ContribuirVaquinha",
});
routes.push({
  path: "/contribuicao-finalizada/:id",
  component: () => import("./pages/FinishDonation.vue"),
  name: "ContribuicaoFinalizada",
});
routes.push({
  path: "/downloads",
  component: () => import("./pages/User/Downloads/Downloads.vue"),
  name: "Downloads",
});
routes.push({
  path: "/passthehats",
  component: () => import("./pages/User/Passthehats/Passthehats.vue"),
  name: "Vaquinha",
});
routes.push({
  path: "/passthehats/create",
  component: () => import("./pages/User/Passthehats/Create.vue"),
  name: "VaquinhaCreate",
});
routes.push({
  path: "/passthehats/show/:id",
  component: () => import("./pages/User/Passthehats/Show.vue"),
  name: "user.passthehats.show",
});
routes.push({
  path: "/loginforced/:token",
  component: () => import("./pages/Home.vue"),
  name: "Login como usuário",
});

routes.push({
  path: "/login",
  component: () => import("./pages/Login.vue"),
  name: "Login",
});
routes.push({
  path: "/dashboard",
  component: () => import("./pages/User/Dashboard.vue"),
  name: "Dashboard",
});
routes.push({
  path: "/profile",
  component: () => import("./pages/User/Profile.vue"),
  name: "Perfil",
});

routes.push({
  path: "/purchases",
  component: () => import("./pages/User/Purchases/Purchases.vue"),
  name: "purchases.index",
  meta: {
    publicName: "Minhas Compras",
    levels: [0],
  },
});

routes.push({
  path: "/purchase/details/:id",
  component: () => import("./pages/User/Purchases/Details.vue"),
  name: "purchases.details",
  meta: {
    publicName: "Detalhes da Compra",
    levels: [0],
  },
});
routes.push({
  path: "/recognize-basis/:eventId",
  component: () => import("./pages/User/Recognize/Basis.vue"),
  name: "recognize.basis",
  meta: {
    publicName: "Reconhecimento Facial - Lista",
    levels: [0],
  },
});
routes.push({
  path: "/recognize/:eventId/:userfaceid",
  component: () => import("./pages/User/Recognize/Recognize.vue"),
  name: "recognize.action",
  meta: {
    publicName: "Reconhecimento Facial",
    levels: [0],
  },
});
routes.push({
  path: "/recognize/:eventId/:userfaceid/choose-best",
  component: () => import("./pages/User/Recognize/ChooseBestAuditImage.vue"),
  name: "recognize.best",
  meta: {
    publicName: "Escolher melhor foto",
    levels: [0],
  },
});
routes.push({
  path: "/recognize/:eventId/:userfaceid/:bestAuditImageId/finish",
  component: () =>
    import("./pages/User/Recognize/SelectBestPictureAndFinish.vue"),
  name: "recognize.finish",
  meta: {
    publicName: "Selecione aonde você melhor aparece",
    levels: [0],
  },
});

routes.push({
  path: "/admin/whitelabels",
  component: () => import("./pages/Admin/Whitelabels/List.vue"),
  name: "admin.whitelabels",
  meta: {
    publicName: "Whitelabels",
    levels: [2],
  },
});
routes.push({
  path: "/admin/whitelabels/new",
  component: () => import("./pages/Admin/Whitelabels/Form.vue"),
  name: "admin.whitelabels.create",
  meta: {
    publicName: "Whitelabels | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/whitelabels/:id",
  component: () => import("./pages/Admin/Whitelabels/Form.vue"),
  name: "admin.whitelabels.edit",
  meta: {
    publicName: "Whitelabels | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/whitelabels/:id/custom-auth",
  component: () => import("./pages/Admin/Whitelabels/CustomAuthForm.vue"),
  name: "admin.whitelabels.custom.auth",
  meta: {
    publicName: "Whitelabels | Configuração de Autenticação Customizada",
    levels: [2],
  },
});

routes.push({
  path: "/admin/whitelabels/:whitelabelId/domains",
  component: () => import("./pages/Admin/Whitelabels/Domains/List.vue"),
  name: "admin.whitelabels.domains",
  meta: {
    publicName: "Domínios de Whitelabel",
    levels: [2],
  },
});
routes.push({
  path: "/admin/whitelabels/:whitelabelId/domains/new",
  component: () => import("./pages/Admin/Whitelabels/Domains/Form.vue"),
  name: "admin.whitelabels.domains.create",
  meta: {
    publicName: "Domínios de Whitelabel | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/whitelabels/:whitelabelId/domains/:id",
  component: () => import("./pages/Admin/Whitelabels/Domains/Form.vue"),
  name: "admin.whitelabels.domains.edit",
  meta: {
    publicName: "Domínios de Whitelabel | Editar",
    levels: [2],
  },
});

routes.push({
  path: "/photographer",
  component: () => import("./pages/Photographer/Dashboard.vue"),
  name: "admin.photographer.dashboard",
  meta: {
    publicName: "Fotógrafo - Dashboard",
    levels: [1, 2, 4],
  },
});
routes.push({
  path: "/photographer/events",
  component: () => import("./pages/Photographer/Dashboard.vue"),
  name: "admin.photographer.events",
  meta: {
    publicName: "Fotógrafo - Projetos",
    levels: [1, 2, 4],
  },
});

routes.push({
  path: "/admin",
  component: () => import("./pages/Admin/Dashboard.vue"),
  name: "admin.index",
  meta: {
    publicName: "Painel Administrador",
    levels: [1, 2, 3, 4],
  },
});
routes.push({
  path: "/admin/users/list/:eventId?",
  component: () => import("./pages/Admin/Users/List.vue"),
  name: "admin.users",
  meta: {
    publicName: "Usuários",
    levels: [1, 2, 4],
  },
});
routes.push({
  path: "/admin/users/create/:eventId?",
  component: () => import("./pages/Admin/Users/Form.vue"),
  name: "admin.users.create",
  meta: {
    publicName: "Usuários | Novo",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/users/edit/:id/:eventId?",
  component: () => import("./pages/Admin/Users/Form.vue"),
  name: "admin.users.edit",
  meta: {
    publicName: "Usuários | Editar",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/sac",
  component: () => import("./pages/Admin/Sac/List.vue"),
  name: "admin.sac",
  meta: {
    publicName: "SAC",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/sac/new",
  component: () => import("./pages/Admin/Sac/Form.vue"),
  name: "admin.sac.create",
  meta: {
    publicName: "SAC | Novo",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/sac/:id",
  component: () => import("./pages/Admin/Sac/Form.vue"),
  name: "admin.sac.edit",
  meta: {
    publicName: "SAC | Editar",
    levels: [2, 4],
  },
});
routes.push({
  name: "events.indexAdmin",
  path: "/admin/events",
  component: () => import("./pages/Admin/Events/List.vue"),
  meta: {
    publicName: "Projetos",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "events.create",
  path: "/admin/events/create",
  component: () => import("./pages/Admin/Events/Form.vue"),
  meta: {
    publicName: "Projetos | Criar",
    levels: [2, 4],
  },
});
routes.push({
  name: "events.edit",
  path: "/admin/events/:id/edit",
  component: () => import("./pages/Admin/Events/Form.vue"),
  meta: {
    publicName: "Projetos | Editar",
    levels: [2, 4],
  },
});
routes.push({
  name: "events.unrecognized.users",
  path: "/admin/events/:id/pictures/unrecognized",
  component: () => import("./pages/Admin/Events/UnrecognizedPictures/List.vue"),
  meta: {
    publicName: "Projetos | Fotos não reconhecidas",
    levels: [1, 2, 4],
  },
});

routes.push({
  name: "categories.indexAdmin",
  path: "/admin/categories",
  component: () => import("./pages/Admin/Categories/List.vue"),
  meta: {
    publicName: "Categorias",
    levels: [2],
  },
});
routes.push({
  name: "categories.create",
  path: "/admin/categories/create",
  component: () => import("./pages/Admin/Categories/Form.vue"),
  meta: {
    publicName: "Categorias | Criar",
    levels: [2],
  },
});
routes.push({
  name: "categories.edit",
  path: "/admin/categories/:id/edit",
  component: () => import("./pages/Admin/Categories/Form.vue"),
  meta: {
    publicName: "Categorias | Editar",
    levels: [2],
  },
});


routes.push({
  name: "sessions.indexAdmin",
  path: "/admin/events/:eventId/sessions",
  component: () => import("./pages/Admin/Sessions/List.vue"),
  meta: {
    publicName: "Eventos | Lista",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "sessions.create",
  path: "/admin/events/:eventId/sessions/create",
  component: () => import("./pages/Admin/Sessions/Form.vue"),
  meta: {
    publicName: "Eventos | Criar",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "sessions.edit",
  path: "/admin/events/:eventId/sessions/:id/edit",
  component: () => import("./pages/Admin/Sessions/Form.vue"),
  meta: {
    publicName: "Eventos | Editar",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "sessionsgalleries",
  path: "/admin/events/:eventId/sessions/:id/galleries",
  component: () => import("./pages/Admin/Galleries/List.vue"),
  meta: {
    publicName: "Eventos | Galerias",
    viewMode: "event",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "galleries.index",
  path: "/admin/galleries",
  component: () => import("./pages/Admin/Galleries/List.vue"),
  meta: {
    publicName: "Galerias | Lista",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "galleries.create",
  path: "/admin/galleries/:eventId/:sessionId/new",
  component: () => import("./pages/Admin/Galleries/Form.vue"),
  meta: {
    publicName: "Galerias | Criar",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "galleries.edit",
  path: "/admin/galleries/:eventId/:sessionId/:id",
  component: () => import("./pages/Admin/Galleries/Form.vue"),
  meta: {
    publicName: "Galerias | Editar",
    levels: [1, 2, 4],
  },
});
routes.push({
  name: "galleries.pictures",
  path: "/admin/galleries-pictureslist/:mode/:id?",
  component: () => import("./pages/Admin/Galleries/PicturesListComponent.vue"),
  meta: {
    publicName: "Fotos | Lista",
    levels: [1, 2, 3, 4],
  },
});
routes.push({
  name: "services.index",
  path: "/admin/events/:eventId/services",
  component: () => import("./pages/Admin/Services/List.vue"),
  meta: {
    publicName: "Serviços | Lista",
    levels: [2, 4],
  },
});
routes.push({
  name: "services.create",
  path: "/admin/events/:eventId/services/create",
  component: () => import("./pages/Admin/Services/Form.vue"),
  meta: {
    publicName: "Serviços | Criar",
    levels: [2, 4],
  },
});
routes.push({
  name: "services.edit",
  path: "/admin/events/:eventId/services/:id/edit",
  component: () => import("./pages/Admin/Services/Form.vue"),
  meta: {
    publicName: "Serviços | Editar",
    levels: [2, 4],
  },
});
routes.push({
  name: "lots.index",
  path: "/admin/events/:eventId/:serviceId/lots",
  component: () => import("./pages/Admin/Lotes/List.vue"),
  meta: {
    publicName: "Lotes | Lista",
    levels: [2, 4],
  },
});
routes.push({
  name: "lots.create",
  path: "/admin/events/:eventId/:serviceId/lots/create",
  component: () => import("./pages/Admin/Lotes/Form.vue"),
  meta: {
    publicName: "Lotes | Criar",
    levels: [2, 4],
  },
});
routes.push({
  name: "lots.edit",
  path: "/admin/events/:eventId/:serviceId/lots/:id/edit",
  component: () => import("./pages/Admin/Lotes/Form.vue"),
  meta: {
    publicName: "Lotes | Editar",
    levels: [2, 4],
  },
});

routes.push({
  name: "cashback.index",
  path: "/admin/events/:eventId/cashback",
  component: () => import("./pages/Admin/ProgressiveCashbacks/List.vue"),
  meta: {
    publicName: "Cashback Progressivo | Lista",
    levels: [2, 4],
  },
});
routes.push({
  name: "cashback.create",
  path: "/admin/events/:eventId/cashback/create",
  component: () => import("./pages/Admin/ProgressiveCashbacks/Form.vue"),
  meta: {
    publicName: "Cashback Progressivo | Criar",
    levels: [2, 4],
  },
});
routes.push({
  name: "cashback.edit",
  path: "/admin/events/:eventId/cashback/:id/edit",
  component: () => import("./pages/Admin/ProgressiveCashbacks/Form.vue"),
  meta: {
    publicName: "Cashback Progressivo | Editar",
    levels: [2, 4],
  },
});
routes.push({
  name: "cashback.status",
  path: "/admin/events/:eventId/cashback/status",
  component: () => import("./pages/Admin/ProgressiveCashbacks/Show.vue"),
  meta: {
    publicName: "Cashback Progressivo | Status",
    levels: [2, 4],
  },
});

routes.push({
  path: "/admin/gateways",
  component: () => import("./pages/Admin/Gateways/List.vue"),
  name: "admin.gateways",
  meta: {
    publicName: "Formas de Pgto",
    levels: [2],
  },
});
routes.push({
  path: "/admin/gateways/new",
  component: () => import("./pages/Admin/Gateways/Form.vue"),
  name: "admin.gateways.create",
  meta: {
    publicName: "Formas de Pgto |Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/gateways/:id",
  component: () => import("./pages/Admin/Gateways/Form.vue"),
  name: "admin.gateways.edit",
  meta: {
    publicName: "Formas de Pgto | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/financial_categories",
  component: () => import("./pages/Admin/FinancialCategories/List.vue"),
  name: "admin.financial.categories",
  meta: {
    publicName: "Categorias Financeiras",
    levels: [2],
  },
});
routes.push({
  path: "/admin/financial_categories/new",
  component: () => import("./pages/Admin/FinancialCategories/Form.vue"),
  name: "admin.financial.categories.new",
  meta: {
    publicName: "Categorias Financeiras | Novo",
    levels: [2],
  },
});
routes.push({
  path: "/admin/financial_categories/:id",
  component: () => import("./pages/Admin/FinancialCategories/Form.vue"),
  name: "admin.financial.categories.edit",
  meta: {
    publicName: "Categorias Financeiras | Editar",
    levels: [2],
  },
});
routes.push({
  path: "/admin/coupons",
  component: () => import("./pages/Admin/Coupons/List.vue"),
  name: "admin.coupons",
  meta: {
    publicName: "Cupons",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/coupons/new",
  component: () => import("./pages/Admin/Coupons/Form.vue"),
  name: "admin.coupons.create",
  meta: {
    publicName: "Cupons | Novo",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/coupons/:id",
  component: () => import("./pages/Admin/Coupons/Form.vue"),
  name: "admin.coupons.edit",
  meta: {
    publicName: "Cupons | Editar",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/purchases/:userId?",
  component: () => import("./pages/Admin/Purchases/List.vue"),
  name: "admin.purchases",
  meta: (route) => ({
    publicName: route?.params?.userId
      ? "Compras de Usuário"
      : "Compras (geral)",
    levels: [2, 4],
  }),
});
routes.push({
  path: "/admin/purchases/details/:id",
  component: () => import("./pages/Admin/Purchases/PurchaseDetails.vue"),
  name: "admin.purchases.details",
  meta: {
    publicName: "Detalhes da Compra",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/payments",
  component: () => import("./pages/Admin/Payments/List.vue"),
  name: "admin.payments",
  meta: {
    publicName: "Pagamentos (geral)",
    levels: [1, 2, 4],
  },
});
routes.push({
  path: "/admin/contacts",
  component: () => import("./pages/Admin/Contacts/List.vue"),
  name: "admin.contacts",
  meta: {
    publicName: "Contatos",
    levels: [1, 2, 4],
  },
});
routes.push({
  path: "/admin/instagram",
  component: () => import("./pages/Admin/Instagram/List.vue"),
  name: "admin.instagram",
  meta: {
    publicName: "Instagram",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/instagram/new",
  component: () => import("./pages/Admin/Instagram/Form.vue"),
  name: "admin.instagram.create",
  meta: {
    publicName: "Instagram | Novo",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/instagram/:id",
  component: () => import("./pages/Admin/Instagram/Form.vue"),
  name: "admin.instagram.edit",
  meta: {
    publicName: "Instagram | Editar",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/reports",
  component: () => import("./pages/Admin/Reports/List.vue"),
  name: "admin.reports",
  meta: {
    publicName: "Relatórios",
    levels: [1, 2, 4],
  },
});
routes.push({
  path: "/admin/config",
  component: () => import("./pages/Admin/Config/List.vue"),
  name: "admin.config",
  meta: {
    publicName: "Configurações",
    levels: [1, 2, 4],
  },
});
routes.push({
  path: "/admin/tutorials",
  component: () => import("./pages/Admin/Tutorial/List.vue"),
  name: "admin.tutorials",
  meta: {
    publicName: "Tutoriais",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/tutorials/new",
  component: () => import("./pages/Admin/Tutorial/Form.vue"),
  name: "admin.tutorials.create",
  meta: {
    publicName: "Tutoriais | Novo",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/tutorials/:id",
  component: () => import("./pages/Admin/Tutorial/Form.vue"),
  name: "admin.tutorials.edit",
  meta: {
    publicName: "Tutoriais | Editar",
    levels: [2, 4],
  },
});
routes.push({
  path: "/tutorial/*",
  component: () => import("./pages/Tutorial.vue"),
  name: "admin.tutorial.general",
  meta: {
    publicName: "Tutorial (geral)",
  },
});
routes.push({
  path: "/admin/pushs",
  component: () => import("./pages/Admin/Push/List.vue"),
  name: "admin.pushs",
  meta: {
    publicName: "Notificações",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/pushs/new",
  component: () => import("./pages/Admin/Push/Form.vue"),
  name: "admin.pushs.create",
  meta: {
    publicName: "Notificações | Criar",
    levels: [2, 4],
  },
});
routes.push({
  path: "/admin/downloads-registry",
  component: () => import("./pages/Admin/DownloadRegistry/List.vue"),
  name: "admin.download.history",
  meta: {
    publicName: "Registro Downloads",
    levels: [1, 2, 4],
  },
});
routes.push({
  path: "/image/editor",
  component: () => import("./pages/User/Gallery/OnlineImageEditor.vue"),
  name: "image.editor",
  meta: {
    publicName: "Editor de Imagens",
  },
});
routes.push({
  path: "/seller",
  component: () => import("./pages/Seller/Dashboard.vue"),
  name: "admin.seller.panel",
  meta: {
    publicName: "Painel Vendedor",
    levels: [2, 3],
  },
});
routes.push({
  path: "/seller/sales",
  component: () => import("./pages/Seller/Sales/List.vue"),
  name: "admin.seller.sales",
  meta: {
    publicName: "Vendas",
    levels: [2, 3],
  },
});
routes.push({
  path: "/seller/sales/new",
  component: () => import("./pages/Seller/Sales/Form.vue"),
  name: "admin.seller.sales.create",
  meta: {
    publicName: "Nova Venda",
    levels: [2, 3],
  },
});
routes.push({
  path: "/seller/sales/edit/:id",
  component: () => import("./pages/Seller/Sales/Form.vue"),
  name: "admin.seller.sales.edit",
  meta: {
    publicName: "Editar Venda",
    levels: [2, 3],
  },
});
routes.push({
  path: "/seller/photos-demo",
  component: () => import("./pages/Seller/Sales/PhotosDemo.vue"),
  name: "admin.seller.photos.demo",
  meta: {
    publicName: "Demonstrativo de Fotos",
    levels: [2, 3],
  },
});
routes.push({
  path: "/admin/users/sellerconfig/:id",
  component: () => import("./pages/Admin/Users/SellerConfigForm.vue"),
  name: "admin.seller.profile",
  meta: {
    publicName: "Perfil Vendedor",
    levels: [2, 4],
  },
});

routes.push({
  path: "/purchase/:code",
  component: () => import("./pages/User/Purchases/FastPurchase.vue"),
  name: "activesale.finish",
  meta: {
    publicName: "Finalização de Compra",
  },
});

const router = new Router({
  history: true,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// Altere o comportamento padrão de navegação duplicada
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};
async function getUser() {
  store.commit("setLoading", true);
  await axios
    .get("/auth/profile")
    .then((r) => {
      store.commit("setUser", r?.data?.user);
    })
    .catch((_) => {
      localStorage.removeItem("token_app");
      store.commit("setUser", null);
    });
}

function afterEach(to) {
  setTimeout(() => {
    store.commit("setLoading", false);
  }, 500);
  Vue.nextTick(() => {
    const meta = typeof to.meta === "function" ? to.meta(to) : to.meta;
    document.title =
      `FotoConect® | ${meta?.publicName ?? to.name}` || "FotoConect®";
  });
}

function resetAxiosConfig() {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token_app")}`,
  };
}

router.beforeEach(async (to, from, next) => {
  if (to.path.includes("loginforced")) {
    let token = to.path.replace("/loginforced/", "");
    localStorage.setItem("token_app", token);
    resetAxiosConfig();
    await getUser();
    if (from.path == "/") afterEach(to);
    return next("/");
  }

  const meta = typeof to?.meta === "function" ? to?.meta(to) : to?.meta;
  if (to.path == "/") {
    await getUser();
  } else if (meta?.levels) {
    let user = store.state.user;
    if (!user) {
      await getUser();
      user = store.state.user;
    }
    if (!user || !meta?.levels?.includes(user?.level)) {
      if (from.path == "/") afterEach(to);
      return next(from.path);
    }
  } else if (!store.state?.user) {
    /**
     * Mesmo que algumas rotas neste ponto não necessitem do usuário, ele ainda será consultado devido a dependências como menu e header.
     * No entanto, não é preciso usar await aqui. Como a rota não exige autenticação, a obtenção do usuário é feita em segundo plano.
     */
    getUser();
  }

  // otherwise allow user to access route, and check if logged and update user data if true.
  return next();
});
router.afterEach((to, from) => {
  afterEach(to);
});
export default router;
